<template>
  <div>

  </div>
</template>

<script>
// Export the SFC
export default {
  // Name of the SFC
  name: "GoogleTrendsReport",
}
</script>
